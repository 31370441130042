import { render, staticRenderFns } from "./newLogin.vue?vue&type=template&id=32017ba0&scoped=true"
import script from "./newLogin.vue?vue&type=script&lang=js"
export * from "./newLogin.vue?vue&type=script&lang=js"
import style0 from "./newLogin.vue?vue&type=style&index=0&id=32017ba0&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32017ba0",
  null
  
)

export default component.exports