<!-- 保留一份旧的登录页面  登录注册-->
<template>
  <div class="container">
    <div class="cover"></div>
    <div id="login_show_box" style="opacity: 1; top: 250px;text-align: left;">
      <div v-show="loginBoxShow" class="bind_box">
        <div class="bind_title">关联手机号</div>
        <div class="bind_con">
          <div class="bind_phone">
            <div class="phone_t">手机号</div>
            <div class="phone_inp_box">
              <div class="inp_box_img">
                <img src="@/assets/sig_phone.png" alt="">
              </div>
              <div class="inp_box">
                <input v-model="diqu" type="text" placeholder="+86" class="diqu" @blur="testphone">
                <span class="line"></span>
                <input v-model="phone" type="number" placeholder="请输入注册手机号" class="phone_inp" :style="phone_err?'background-color:#ffd2d2;color:#ee2e2e;':''" @blur="testphone">
              </div>
            </div>
            <div v-show="phone_err" class="error_msg">请输入正确手机号!</div>
          </div>
          <div class="bind_code">
            <div class="bind_phone1">
              <div class="phone_t">验证码</div>
              <div class="code_inp_box">
                <div class="code_items">
                  <div class="inp_box_img">
                    <img src="@/assets/sig_code.png" alt="">
                  </div>
                  <input v-model="ucode" type="number" placeholder="请输入验证码" class="code_inp">
                </div>
                <div class="btns">
                  <span v-show="iscode" class="code_btn" @click="getcode">{{ code_text }}</span>
                  <span v-show="againcode" class="code_btn">{{ code_text }}</span>
                </div>
              </div>
              <div v-show="code_err" class="code_error_msg">{{ code_err_msg }}!</div>
            </div>
          </div>
        </div>
        <div class="bind_btn" @click="bindWithWX">关联/注册</div>
        <div class="back_login">
          <span @click="back_login">返回登录</span>
        </div>
      </div>
      <div v-loading="log_set_loading" class="reg_zone">
        <span class="close7" @click="close">
          <img src="https://passport.zhulong.com/img/loginimg/x.png">
        </span>
        <div class="rzone-t">
          <h1></h1>
          <!-- <img
            src="https://passport.zhulong.com/img/regimg/logo-zl.png"
            alt=""
          > -->
        </div>
        <ul v-if="mobile_login+username_login+code_login>1" id="btns">
          <li v-if="code_login" :style="mobile_login+username_login+code_login==2?'width:50%':'width:33%'" :class="phoneOrnum==3 ? 'login-active' : 'no-active'" @click=";(phoneOrnum = 3), choosetypt(3)">
            公众号登录
          </li>
          <li v-if="mobile_login" :style="mobile_login+username_login+code_login==2?'width:50%':'width:33%'" :class="phoneOrnum==1 ? 'login-active' : 'no-active'" @click=";(phoneOrnum = 1), choosetypt(1)">
            验证码登录
          </li>
          <li v-if="username_login" :style="mobile_login+username_login+code_login==2?'width:50%':'width:33%'" :class="phoneOrnum==2 ? 'login-active' : 'no-active'" @click=";(phoneOrnum = 2), choosetypt(2)">
            账号登录
          </li>
        </ul>
        <!-- 如果只有一种登录状态改成大标题 -->
        <div v-if="mobile_login+username_login+code_login==1&&code_login" class="login_title">
          公众号登录
        </div>
        <div v-if="mobile_login+username_login+code_login==1&&mobile_login" class="login_title">
          验证码登录
        </div>
        <div v-if="mobile_login+username_login+code_login==1&&username_login" class="login_title">
          账号登录
        </div>
        <div class="login_denglu_left reg-form">
          <ul id="content1">
            <li :class="phoneOrnum==1 ? 'show' : 'noshow'">
              <div class="skin">
                <label for="tel">手机号:</label>
                <div class="inp-out bor_red">
                  <i class="iconfont icon-comiisyonghuming-copy icon1">&#xe622;</i>
                  <input id="tel" v-model.trim="phone" class="bor_red" placeholder="请输入手机号" style="width: 210px; padding-left: 68px" @blur="testphone">
                  <input v-model="diqu" style="width: 40px; margin-left: 10px" type="text" placeholder="+86" class="diqu" @blur="testphone">
                </div>
                <div v-show="phone_err" class="popo">
                  <span class="pol"></span>
                  <span class="pom">
                    <img src="https://passport.zhulong.com/img/loginimg/warn.png">
                    请输入正确的手机号
                  </span>
                  <span class="por"></span>
                </div>
              </div>
              <div class="skin">
                <label for="tel">验证码:</label>
                <div class="inp-out bor_red error-red" style="width: 192px; border: 1px solid rgb(238, 238, 238)">
                  <i class="iconfont icon-mima-copy icon2"></i>
                  <input id="Ucode" v-model="ucode" class="bor_red" type="text" style="width: 150px" placeholder="请输入验证码"><br>
                </div>
                <span v-show="iscode" id="Getcode" class="pray" @click="getcode">获取验证码</span>
                <span v-show="againcode" id="Getcode1" class="pray">{{ code_text }}</span>
                <div v-show="code_err" class="popo">
                  <span class="pol"></span>
                  <span class="pom error"><img src="https://passport.zhulong.com/img/loginimg/warn.png">请输入正确的验证码</span>
                  <span class="por"></span>
                </div>
              </div>
            </li>
            <li :class="phoneOrnum==2 ? 'show' : 'noshow'">
              <div class="skin">
                <label style="" for="Uname">账号:</label>
                <div class="inp-out bor_red">
                  <i class="iconfont icon-comiisyonghuming-copy icon3"></i>
                  <input id="Uname" v-model="num" class="bor_red" type="text" name="loginName" placeholder="请输入账号/邮箱/手机号" @blur="testnum"><br>
                </div>
                <div v-show="errnum" class="popo">
                  <span class="pol"></span>
                  <span class="pom">
                    <img src="https://passport.zhulong.com/img/loginimg/warn.png">
                    4-16位字符,不能使用特殊符号
                  </span>
                  <span class="por"></span>
                </div>
              </div>
              <div class="skin">
                <label style="" for="tel">密码:</label>
                <div class="inp-out bor_red" style="border: 1px solid rgb(238, 238, 238)">
                  <i class="iconfont icon-mima-copy icon4"></i>
                  <input v-show="is_see" v-model="password" class="bor_red" type="password" style="width: 220px" placeholder="请输入密码" maxlength="18">
                  <input v-show="!is_see" v-model="password" class="bor_red" type="text" style="width: 220px" placeholder="请输入密码" maxlength="18"><br>
                  <span class="eye">
                    <img v-show="is_see" src="https://passport.zhulong.com/img/loginimg/bukejian.png" @click="is_see = false">
                    <img v-show="!is_see" src="https://passport.zhulong.com//img/loginimg/kejian.png" @click="is_see = true">
                  </span>
                </div>
                <div v-show="passworderr" class="popo">
                  <span class="pol"></span>
                  <span class="pom">
                    <img src="https://passport.zhulong.com/img/loginimg/warn.png">
                    密码错误
                  </span>
                  <span class="por"></span>
                </div>
              </div>
              <div v-show="isUcode" class="Tishi_code" style="margin-bottom: 10px">
                <label for="Ucode">验证码：</label>
                <input
                  v-model="newcode"
                  type="text"
                  name="seccode"
                  style="
                    border: 1px solid #e6e6e6;
                    height: 38px;
                    width: 145px;
                    margin-top: 0px;
                    padding-left: 10px;
                    text-align: left;
                  "
                  placeholder="请输入验证码"
                >
                <span
                  class="yanzhengtu"
                  style="
                    display: inline-block;
                    width: 100px;
                    height: 55px;
                    line-height: 50px;
                    text-align: center;
                  "
                >
                  <img :src="codeimg" style="vertical-align: middle; padding-left: 0px">
                </span>
                <span
                  href="javascript:void(0);"
                  style="
                    font-size: 12px;
                    border-bottom: 1px solid dodgerblue;
                    color: dodgerblue;
                    cursor: pointer;
                  "
                  @click="onSwitch"
                >换一张</span>
                <div class="popo">
                  <span class="pol"></span>
                  <span class="pom">
                    <img src="https://passport.zhulong.com/img/loginimg/warn.png">
                    验证码错误
                  </span>
                  <span class="por"></span>
                </div>
              </div>
            </li>
            <li :class="phoneOrnum==3 ? 'show' : 'noshow'">
              <div class="qrcode_box">
                <!-- <div class="qr_title">微信安全登录</div> -->
                <div class="qr_img">
                  <div v-loading="qr_loading">
                    <img v-show="wxcode_url" :src="wxcode_url" alt="">
                    <div v-show="wxcode_url" class="qr_logo">
                      <img :src="qr_logo" alt="">
                    </div>
                    <!-- <img src="https://f.zhulong.com/qrcode.php?data=http%3A%2F%2Fweixin.qq.com%2Fq%2F02CpHxoWvTbc_1Urh21yc8" alt=""> -->
                  </div>
                </div>
                <!-- <div class="qr_msg">微信扫码关注公众号即可登录</div> -->
                <div class="qr_msg">微信扫码 <span>关注「{{ nick_name }}」公众号</span> 即可登录</div>
                <p class="login-intr" :style="phoneOrnum==3?'width:100%;':'width:110%;'">
                  未注册微信登录后自动注册，即代表同意
                  <router-link :to="{path:'/agreement',query: {type: 4}}">《注册协议》</router-link>
                </p>
              </div>
            </li>
          </ul>
          <div v-if="phoneOrnum==1||phoneOrnum==2">
            <div style="padding: 0 60px 0 50px; line-height: 30px; color: #999999">
              <input id="Remember" type="radio" name="isremember" value="1">
              <label class="label_gai" style="padding-left: 0; background-size: 13px" @click="islong"><i
                                                                                                        v-show="long"
                                                                                                        class="iconfont icon-weixuanzhong wei"
                                                                                                        style="
                    font-size: 12px;
                    color: #ee2e2e;
                    padding-left: 1px;
                  "
                                                                                                      ></i>
                <i v-show="little" class="iconfont icon-xuanzhong wei" style="font-size: 12px; color: #ee2e2e; padding-left: 1px"></i>&nbsp;我的电脑可长期登录</label>
              <!-- <a href="https://passport.zhulong.com/findpwd/findpwd" class="forgetpass" style="display: inline">忘记密码？</a> -->
              <div>
                <!-- <a href="javascript:void(0);" class="denglu_btn" style="margin-top: 10px; float: left" @click="loginbtn()">注册/登录</a> -->
                <span class="denglu_btn" style="margin-top: 10px; float: left;cursor: pointer;" @click="loginbtn()">注册/登录</span>
              </div>
            </div>
            <p class="login-intr">
              未注册手机验证后自动登录，注册即代表同意
              <router-link :to="{path:'/agreement',query: {type: 4}}">《注册协议》</router-link>
              <!-- <a
                target="_blank"
                href="https://passport.zhulong.com/clause.html"
                style="color: #999"
              >《注册协议》</a> -->
            </p>
            <div class="others-login">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '@/styles/weblogin.css'
import base64 from '@/utils/base64'
import Cookies from 'js-cookie'
import { signInByQrcode, loginScore, getBbsQrCode, sendMobileCodeNew, loginByUserName, loginByPhone, getChangeWxBindQrcode, getwxqrcode, weixinBind, getSetting, getAgencyInfo } from '@/api/login.js'
import {
  getProvinceCityList
} from '@/api/mine'
import { JSEncrypt } from 'jsencrypt'

export default {
  data() {
    return {
      setDisabled: {
        disabledDate(time) {
          return time.getTime() > Date.now() // 可选历史天、可选当前天、不可选未来天
        }
      },
      cascaderProps: {
        lazy: true,
        lazyLoad(node, resolve) {
          getProvinceCityList({
            level: node.level,
            parent_id: node.value
          }).then(res => {
            const nodes = Array.from(res.result).map(item => ({
              label: item.name,
              value: item.id,
              leaf: node.level >= 2
            }))
            resolve(nodes)
          })
        }
      },
      cityArr: [],
      date_: '',
      phoneOrnum: 3,
      phone: '',
      loginBoxShow: false,
      diqu: '+86',
      ucode: '',
      num: '',
      password: '',
      dialogVisible: false,
      followListArr: [],
      newcode: '',
      phone_err: false,
      code_err: false,
      is_see: true,
      type: 1,
      account_name: '',
      mima: '',
      code: '',
      appcode: '',
      errnum: false,
      bbsqrcode: {
        qr_page_code: ''
      },
      passworderr: false,
      cansend: false,
      code_text: '获取验证码',
      ticket: '',
      randstr: '',
      iscode: true,
      againcode: false,
      timer: null,
      s: 60,
      long: false,
      little: true,
      is_long: false,
      isUcode: false,
      codeimg: '',
      timelogin: null,
      redirect: '', // 来自哪个路由,
      qrcode_url: '',
      qrcode_des: '',
      avatar: '', // 用户头像
      uploadBoxShow: false,
      informationBoxShow: false,
      followBoxShow: false,
      cityOptions: [],
      formData: {
        name: '',
        gender: 1,
        realname: '',
        school_name: '',
        major: '',
        specialty: '',
        certificate: '',
        graduation_time: ''
      },
      followQuery: {
        limit: 9,
        page: 1
      },
      selNum: 0,
      followMaxNum: 1,
      interestBoxShow: false,
      followZlbNum: 0,
      updateAvatarZlbNum: 0,
      infoZlbNum: 0,
      code_err_msg: '',
      // 微信扫码登录
      wxcode_url: '',
      wss_url: '',
      websock: '',
      websock_open: false,
      unionID: '',
      openid: '',
      qr_loading: false,
      tips: '',
      agency_name: '',
      nick_name: '',
      sig_logo: '',
      qr_logo: '',
      code_login: 0,
      mobile_login: 0,
      username_login: 0,
      log_set_loading: true
    }
  },
  computed: {},
  watch: {
    $route: {
      handler: function(route) {
        // this.redirect = decodeURIComponent(route.query.redirect) === 'undefined' ? 'https://www.zhulong.com' : decodeURIComponent(route.query.redirect)
      },
      immediate: true
    }
  },
  created() {
    this.getParams()
    this.getAgencyInfoFun()
    this.getLoginSetting()
  },
  methods: {
    back_login() {
      this.loginBoxShow = false
      this.wxCodeShow = true
      this.code = ''
      this.phone = ''
      this.code_text = '获取验证码'
      clearInterval(this.timer)
    },
    // 获取登录方式配置
    getLoginSetting() {
      getSetting().then((res) => {
        if (res.errNo == 0) {
          this.code_login = res.result.code_login
          this.mobile_login = res.result.mobile_login
          this.username_login = res.result.username_login
          // 如果只有验证码登录
          if (this.code_login + this.mobile_login + this.username_login == 1 && this.mobile_login) {
            this.phoneOrnum = 1
          }
          // 如果只有账号密码登录
          if (this.code_login + this.mobile_login + this.username_login == 1 && this.username_login) {
            this.phoneOrnum = 2
          }
          // 如果只有扫码登录
          if (this.code_login + this.mobile_login + this.username_login == 1 && this.code_login) {
            this.phoneOrnum = 3
          }
          // 如果有两种登录状态 且其中含公众号登录
          if (this.code_login + this.mobile_login + this.username_login == 2) {
            if (this.code_login) {
              this.phoneOrnum = 3
            } else {
              this.phoneOrnum = 1
            }
          }
          // 如果三种登录状态都含有
          if (this.code_login + this.mobile_login + this.username_login == 3) {
            this.phoneOrnum = 3
          }
          if (res.result.code_login) {
            this.funcGetwxqrcode()
          }
        }
        this.log_set_loading = false
      })
    },
    // 获取机构名称
    getAgencyInfoFun() {
      getAgencyInfo().then(res => {
        if (res.errNo == 0) {
          const result = res.result || {}
          this.sig_logo = result.thumb
          this.qr_logo = result.icon_url
          const name = result.short_name
          Cookies.set('company_name', name)
        }
      })
    },
    funcGetwxqrcode() {
      this.qr_loading = true
      getwxqrcode().then(res => {
        this.qr_loading = false
        if (res.errNo === 0) {
          this.wxcode_url = res.result.url
          this.scene_id = res.result.scene_id
          this.nick_name = res.result.nike_name

          this.wss_url = 'wss://www.zhulong.com/wss_socket/'
          this.creatSocket()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(err => {
        this.qr_loading = false
        console.log(err)
      })
    },
    // ---------- socket 处理 -----------
    creatSocket() {
      if (this.websock_open) {
        return
      }
      this.websock = new WebSocket(this.wss_url)
      this.websock.onopen = this.websocketonopen
      this.websock.onmessage = this.websocketonmessage
      this.websock.onerror = this.websocketonerror
      this.websock.onclose = this.websocketclose
    },
    websocketonopen(res) {
      // 链接socket
      this.websock_open = true
      console.log('连接成功')
      const params = {
        type: 'login',
        uid: this.scene_id
      }
      this.sendToServer(params)
    },
    websocketonmessage(e) {
      // 接收消息
      var data = JSON.parse(e.data)
      switch (data.type) {
        case 'bind':
          console.log(this.redirect, '---------this.redirect')
          // 已经绑定过
          var uid = data.uid || ''
          var url = data.url + '&redirect_uri=' + base64.base64encode(this.redirect)// 加密(this.redirect)
          console.log(url, uid)
          // this.zlTracker('newSCanloginSuccess', uid)
          window.location.href = url
          break
        case 'unbind':
          // 未绑定过
          this.unionID = data.unionID || ''
          this.openid = data.openid || ''
          this.wxCodeShow = false
          this.loginBoxShow = true
          // 扫完码弹出绑定框的时候埋点
          // this.zlTracker('newloginScanSuccess', this.unionID)
          break
        default:
          break
      }
    },
    websocketonerror(res) {
      console.log('websock 出现错误', res)
      this.websock_open = false
    },
    websocketclose() {
      this.websock_open = false
      this.creatSocket()
    },
    sendToServer(data) {
      // 给服务端发送消息
      var str = JSON.stringify(data)
      this.websock.send(str)
    },
    // ---------- socket 处理结束 -----------
    getParams() {
      if (decodeURIComponent(this.$route.query.redirect) === 'undefined') this.redirect = '/'
      else {
        const urlArr = decodeURIComponent(window.location.href).split('?')
        console.log(urlArr)
        let paramsStr = ''
        for (let index = 1; index < urlArr.length; index++) {
          const element = urlArr[index]
          if (index === 1) {
            const pArr = element.split('=')
            console.log(pArr)
            const arr = pArr[1].split(':')
            console.log(arr)
            const hostname = arr[1] || ''
            if (arr[0] === 'http' && !hostname.includes('job')) arr[0] = 'https'
            // const pStr = arr.join(':')
            pArr[1] = arr.join(':')
            pArr.splice(0, 1)
            paramsStr += pArr.join('=')
          } else {
            paramsStr += '?' + element
          }
        }
        this.redirect = paramsStr
      }
      console.log(this.redirect)
    },
    onSwitch() {
      this.codeimg = this.codeimg.split('?')[0] + `?r = ${Math.random()}`
    },

    setSex(n) {
      this.formData.gender = n
    },
    skipTask() {
      // console.log('跳过')
      window.location.href = this.redirect
    },
    encrypt(msg) {
      var encrypt = new JSEncrypt()
      encrypt.setPublicKey(
        '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCrWVnSOu9m7O8X3taQGXzVlB9B0Gw1Mvbc0MxKZOxT8SlQVB1Krpu3KfuoxgGE1TikX/JkYJf+D4tTqENqk5pnSZc784gWZPEs2O+uz5R/8Ay8qP06uHDzw1oGDrpo8wxWQ7Ae2IwE2gTDtpcyg8NUJp14uYwsvA47iDpXHGmPxQIDAQAB-----END PUBLIC KEY-----'
      )
      return encrypt.encrypt(msg)
    },
    close() {
      this.$emit('close', false)
      window.location.href = this.redirect
    },
    getcode() {
      if (this.cansend === true) {
        const appid = '2090316930' // 腾讯云控制台中对应这个项目的 appid
        // 生成一个滑块验证码对象
        const that = this
        const captcha = new TencentCaptcha(appid, function (res) { // eslint-disable-line
          // 用户滑动结束或者关闭弹窗，腾讯返回的内容
          if (res.ret === 0) {
            // 成功，传递数据给后台进行验证
            that.iscode = false
            that.againcode = true
            that.randstr = res.randstr
            that.ticket = res.ticket
            that.gettime()
            var info = {
              guoji: that.diqu,
              mobile: that.phone,
              ticket: that.ticket,
              randstr: that.randstr,
              code_type: 1
            }
            // sendMobileCode(info).then(res => {
            //   console.log(res, '手机号登录')
            // })
            sendMobileCodeNew(info).then(res => {
              console.log(res, '手机号登录')
              if (res.errNo !== 0) {
                that.$message.error(res.msg)
              }
            })
          } else {
            // 提示用户完成验证
          }
        })
        // 滑块显示
        captcha.show()
      }
    },
    choosetypt(type) {
      this.type = type
      if (this.type === 1) {
        this.account_name = this.phone
      } else if (this.type === 2) {
        this.account_name = this.num
      }
    },
    bindWithWX() {
      if (this.diqu == '') {
        this.diqu = '+86'
      }
      const info = {
        mobile: this.diqu + this.phone,
        code: this.ucode,
        unionID: this.unionID,
        openid: this.openid
      }
      this.code_err = false
      this.code_err_msg = ''
      weixinBind(info).then(res => {
        console.log(res)
        // if (res.errNo !== 0) this.zlTracker('newloginFail', res.errNo)
        if (res.msg == '该账号已绑定其他微信') {
          this.$message({ message: '该账号已绑定其他微信', type: 'error', duration: 20000 })
          return
        }
        if (res.errNo === 0) {
          // this.zlTracker('newloginSuccess', res.result.uid)
          // 绑定成功--登录
          this.loginScore()
          if (res.result.type === 'reg') this.getNewTaskInfo()
          else window.location.href = `${this.redirect}`
          // 成功之后跳转页面
        } else if (res.errNo === 1007) {
          this.code_err = true
          this.code_err_msg = res.msg
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    loginbtn() {
      if (this.type === 1) {
        this.account_name = this.phone
        this.mima = ''
        this.code = this.ucode
        this.loginByPhone()
      } else if (this.type === 2) {
        this.code = this.newcode
        this.account_name = this.num
        this.mima = this.encrypt(this.password)
        this.loginByUserName()
      }
    },
    // 用户名密码登录(新)
    loginByUserName() {
      const info = {
        loginname: this.num,
        password: this.password,
        from_url: '',
        agency_id: Cookies.get('agency_id')
      }
      loginByUserName(info).then(res => {
        if (res.errNo === 0) {
          // 登录成功
          console.log(res, '登录成功')
          this.loginScore()
          this.passworderr = false
          // this.close()
          Cookies.set('uid', res.result.uid, { expires: 7 })
          Cookies.set('username', res.result.username, { expires: 7 })
          Cookies.set('uuid', res.result.uuid, { expires: 7 })
          Cookies.set('access_token', res.result.access_token, { expires: 7 })

          console.log(Cookies.get('uid'), '--------------------uid')
          console.log(Cookies.get('username'), '--------------------username')
          window.location.href = `${this.redirect}`
          // 成功之后跳转页面
          //
        } else if (res.errNo === 161) {
          this.passworderr = true
        } else if (res.errNo === 1009) {
          this.isUcode = true
          this.codeimg = res.result
        } else if (res.errNo === 160) {
          this.$router.push({ path: '/bindPhone', query: { username: res.result.username, redirect: this.redirect }})
        } else if (res.errNo === 2003) {
          this.$message.error(res.msg)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 手机验证码登录(新)
    loginByPhone() {
      const info = {
        mobile: this.phone,
        code: this.ucode,
        from_url: '',
        agency_id: Cookies.get('agency_id')
      }
      loginByPhone(info).then(res => {
        if (res.errNo === 0) {
          // 登录成功
          console.log(res, '登录成功')
          // this.loginScore()
          this.passworderr = false
          // this.close()
          Cookies.set('uid', res.result.uid, { expires: 7 })
          Cookies.set('username', res.result.username, { expires: 7 })
          Cookies.set('uuid', res.result.uuid, { expires: 7 })
          // Cookies.set('agency_id', res.result.agency_id, { expires: 7 })
          Cookies.set('access_token', res.result.access_token, { expires: 7 })
          if (res.result.real_name) {
            Cookies.set('real_name', res.result.real_name, { expires: 7 })
          }
          console.log(Cookies.get('uid'))
          console.log(Cookies.get('username'))
          window.location.href = `${this.redirect}`
        } else if (res.errNo === 161) {
          this.passworderr = true
        } else if (res.errNo === 1009) {
          this.isUcode = true
          this.codeimg = res.result
        } else if (res.errNo === 160) {
          this.$router.push({ path: '/bindPhone', query: { username: res.result.username, redirect: this.redirect }})
        } else if (res.errNo === 2003) {
          this.$message.error(res.msg)
        }
      })
    },

    testnum() {
      var reg = /^[a-zA-Z0-9\u4e00-\u9fa5]{4,16}$/g
      var _match = reg.test(this.num)
      if (!_match) {
        this.errnum = true
      } else {
        this.errnum = false
      }
      console.log(this.errnum)
    },
    testphone() {
      var reg = /^(1[0-9]{10})$/ // 手机号
      if (this.diqu == '+86' || this.diqu == '86' || this.diqu == '') {
        if (reg.test(this.phone)) {
          this.phone_err = false
          this.cansend = true
        } else {
          this.phone_err = true
          this.cansend = false
        }
      } else {
        if (this.phone == '') {
          this.phone_err = true
          this.cansend = false
        } else {
          this.phone_err = false
          this.cansend = true
        }
      }
    },
    gettime() {
      this.timer = setInterval(() => {
        // 某些定时器操作
        this.s--
        this.code_text = '重发(' + this.s + ')'
        if (this.s <= 0) {
          this.iscode = true
          this.againcode = false
          clearInterval(this.timer)
          this.code_text = '重新发送'
          this.s = 60
        }
      }, 1000)
    },
    islong() {
      this.is_long = !this.is_long
      if (this.is_long === false) {
        this.long = false
        this.little = true
      } else {
        this.little = false
        this.long = true
      }
    },
    // 登录成功送积分
    async loginScore() {
      console.log('执行了')
      const res = await loginScore()
      console.log(res, 'res')
    }
  }
}
</script>
<style lang='less' scoped>
* {
  box-sizing: content-box;
}
.jus-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jus-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jus-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.jus-spB {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.upload_img_box {
  margin: -250px -275px;
  width: 550px;
  height: 500px;
}
.follow_box {
  margin: -285px -468px;
  width: 936px;
  height: 570px;
  .finish_btn {
    margin-top: 30px !important;
    position: relative;
    .change_peo {
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: #ee2e2e;
      letter-spacing: 0;
      text-align: center;
      position: absolute;
      top: 0;
      left: -70px;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .people_list {
    flex-wrap: wrap;
    height: 351px;
    .people_ltem:nth-child(3n) {
      margin-right: 0;
    }
    .people_ltem {
      height: 97px;
      margin-top: 20px;
      padding: 11px 10px;
      align-items: flex-start !important;
      border: 1px solid #e1e1e1;
      box-sizing: border-box;
      margin-right: 29px;
      width: 278px;

      .people_ltem_img {
        width: 46px;
        height: 46px;
        border-radius: 5px;
        margin-right: 10px;
      }
      .people_ltem_content {
        .peo_name {
          font-family: PingFangSC-Regular;
          font-size: 18px;
          color: #333333;
          letter-spacing: 0;
          width: 200px;
          overflow: hidden;

          text-overflow: ellipsis;

          white-space: nowrap;
        }
        .peo_icon {
          width: 14px;
          display: block;
          margin: 0;
          margin-right: 5px;
        }
        .peo_btn_ly {
          background-color: #f4f4f4 !important;
          color: #999 !important;
          cursor: default !important;
        }
        .peo_btn {
          .add_img {
            width: 13px;
            display: block;
            margin: 0;
          }
          .btn_text {
            font-family: PingFangSC-Medium;
            font-size: 12px;
            color: #ffffff;
            letter-spacing: 0;
          }
          background-color: #ee2e2e;
          padding: 5px 8px;
          border-radius: 999px;
          cursor: pointer;
        }
        .peo_btn_ly {
          background-color: #f4f4f4 !important;
          .btn_text {
            color: #999999 !important;
          }
        }
      }
    }
  }
  .peo_info_text {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #999999;
    letter-spacing: 0;
  }
}
.information_box {
  margin: -385px -275px;
  width: 550px;
  height: 770px;
  .interest_box {
    position: absolute;
    width: 300px;
    box-sizing: border-box;
    height: 250px;
    background-color: #fff;
    top: 41px;
    left: 0;
    z-index: 100;
    padding: 0 10px;
    border: 1px solid #e9e9e9;
    overflow: auto;
    .title_ {
      font-family: PingFangSC-Medium;
      font-size: 15px;
      color: #333333;
      letter-spacing: 0;
      text-align: left;
      border-bottom: 1px solid #e9e9e9;
    }
    .sub_title {
      font-family: PingFangSC-Semibold;
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
    }
    .interest_item_box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      .__item {
        font-family: PingFangSC-Regular;
        font-size: 15px;
        color: #666666;
        letter-spacing: 0;
        text-align: center;
        margin-right: 10px;
        cursor: pointer;
      }
      .__item:nth-child(4n) {
        margin: 0;
      }
    }
  }
  .title_info {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #999999;
    letter-spacing: 0;
    line-height: 23px;
    background-color: #f7f7f7;
    text-align: center;
    margin: 10px 0;
  }
  .input_class {
    width: 300px;
  }
  .next_btn {
    width: 300px !important;
    margin: 0 !important;
  }
  .form_class {
    padding: 20px 0;
  }
}
.box_ {
  position: fixed;
  top: 50%;
  left: 50%;

  background-color: #fff;
  box-shadow: 0 1px 16px 0 rgba(51, 51, 51, 0.1);
  z-index: 601;
  padding: 22px 22px;
  box-sizing: border-box;
  .icon_upload {
    text-align: center;
  }
  .jump_ {
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #999999;
    letter-spacing: 0;
    text-align: center;
    cursor: pointer;
  }
  .title_ {
    font-family: PingFangSC-Medium;
    font-size: 24px;
    color: #333333;
    letter-spacing: 0;
    text-align: center;
  }
  .user_img_box {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background-color: #e4e4e4;
    margin: 20px auto;
    box-sizing: border-box;
    .photo_img {
      display: block;
      width: 60px;
      margin: 0 auto;

      padding-top: 25px;
    }
    .user_img_ {
      width: 100%;
      height: 100%;
      border-radius: 999px;
      display: block;
      margin: 0;
    }
  }
  .text_box {
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #333333;
    letter-spacing: 0;
    text-align: center;
    line-height: 23px;
    .hei_light {
      color: #ee2e2e;
    }
  }
  .sex_box {
    margin-top: 20px;
    .sex_item {
      margin: 0 10px;
      line-height: 45px;
      width: 128px;
      box-sizing: border-box;
      background-color: #e4e4e4;
      border-radius: 999px;
      font-family: PingFangSC-Medium;
      font-size: 15px;
      color: #ffffff;
      letter-spacing: 0;
      text-align: center;
      .sex_icon {
        width: 22px;
        display: block;
        margin: 0;
        margin-right: 10px;
      }
    }
    .sex_item_active {
      background-color: #ee2e2e;
    }
  }
  .next_btn {
    background-color: #ee2e2e;
    line-height: 53px;
    width: 345px;
    margin: 0 auto;
    margin-top: 50px;
    font-family: PingFangSC-Medium;
    font-size: 18px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    cursor: pointer;
  }
}
.close7 {
  cursor: pointer;
}
.rzone-t {
  // padding-left:56px !important;
  h1 {
    width: 110px;
    height: 37px;
    background: url("../../assets/login_logo.png") no-repeat;
    // background: url('https://passport.zhulong.com/img/regimg/logo-zl.png') -152px 0 no-repeat;
  }
}
.qr_page_code {
  display: block;
  margin-right: 20px;
  width: 140px;
}
.container{
  min-height: 800px;
}

// ----- 新 -----
  .bind_box{
    width: 578px;
    height: 448px;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 800;
    background-color: #fff;
    margin: -240px -325px;
    padding: 0 36px;
    // font-family: 'PingFangSC';
    font-family: PingFangSC-Medium;
    color: #333;
  }
  .logo{
    margin-top: 18px;
    margin: 18px 0 0 -10px;
    height: 52px;
    display: flex;
    align-items: center;
    img{
      max-width:200px;
      max-height: 50px;
    }
  }
  .qrcode_box{
    // margin-top: 12px;
    // background-color: aquamarine;
    min-height: 282px;
    padding-bottom:24px;
    .qr_title{
      font-size: 24px;
      font-weight: 500;
      text-align: center;
    }
    .qr_img{
      // background-color: #fff;
      display: flex;
      justify-content: center;
      margin: 10px 0;
      div{
        width: 180px;
        height: 180px;
        // padding: 20px;
        // background-color: aqua;
        position: relative;
      }
      img{
        width: 100%;
        height: 100%;
        background-color: #fff;
      }
      .qr_logo{
        position: absolute;
        width: 44px;
        height: 44px;
        padding: 3px;
        background-color: #fff;
        border-radius: 3px;
        top: calc(50% - 22px);
        left: calc(50% - 22px);
        img{
          width: 42px;
          height: 42px;
          border-radius: 3px;
          border: 1px solid #999;
        }
      }
    }
    .qr_msg{
      font-size: 16px;
      color: #ee2e2e;
      text-align: center;
      font-family: PingFangSC-Regular;
      span{
        font-family: PingFangSC-Medium;
        font-weight: 500;
      }
    }
  }

  .bind_title{
    font-size: 24px;
    font-weight: 500;
    text-align: left;
    margin-top: 32px;
    margin: 32px 0 30px;
  }
  .bind_con{
    width: 300px;
    margin-left: 25%;
    // background-color: cadetblue;
  }
  .bind_phone1{
    padding-bottom: 35px;
    position: relative;
  }
  .bind_phone,.bind_phone1{
    .phone_t{
      font-size: 13px;
      height: 30px;
      color: #333333;
      // background-color: burlywood;
    }
    .phone_inp_box,.code_inp_box{
      background-color: #fff;
      height: 40px;
      width: 298px;
      // border: 1px solid #eee;
      border: 1px solid rgba(153, 153, 153, 0.5);
      line-height: 40px;
      display: flex;
      align-items: center;
      border-radius: 3px;
      .inp_box_img{
        width: 40px;
        height: 40px;
        background-color: #f8f8f8;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        img{
          width: 20px;
        }
      }
      .inp_box{
        display: flex;
        align-items: center;
        // background-color: chocolate;
        .diqu{
          width: 40px;
          text-align: center;
          // background-color:chartreuse;
        }
        .line{
          display: inline-block;
          background-color: #eee;
          width: 1px;
          height: 30px;
        }
        .phone_inp{
          height: 38px;
          width: 196px;
          padding: 0 10px;
          // background-color: lightskyblue;
        }
      }
    }
    .code_inp_box{
      border: 1px solid #fff;
      // background-color: cadetblue;
      .code_items{
        width: 160px;
        display: flex;
        align-items: center;
        // border: 1px solid #eee;
        border: 1px solid rgba(153, 153, 153, 0.5);
        border-radius: 3px;
        // background-color: blueviolet;
        .code_inp{
          width: 107px;
          height: 38px;
          padding: 0 5px;
          // background-color: darkcyan;
        }
      }
      .btns{
        .code_btn{
          display: inline-block;
          width: 121px;
          margin-left: 15px;
          font-size: 13px;
          text-align: center;
          color: #ee2e2e;
          border: 1px solid #ee2e2e;
          border-radius: 3px;
          background-color: #fff;
          cursor: pointer;
        }
      }
    }
    .error_msg,.code_error_msg{
      margin-top: 5px;
      height: 15px;
      color: #ee2e2e;
    }
    .code_error_msg{
      position: absolute;
      left: 0;
      bottom: 15px;
    }
  }
  .bind_code{
    margin-top: 20px;
  }
  .bind_btn{
    // margin-top: 35px;
    width: 300px;
    height: 40px;
    line-height: 40px;
    margin-left: 25%;
    font-size: 16px;
    border-radius: 3px;
    text-align: center;
    color: #fff;
    background-color: #ee2e2e;
    cursor: pointer;
  }
  .back_login{
    width: 300px;
    height: 20px;
    margin: 15px 0 0 25%;
    text-align: center;
    span{
      font-size: 12px;
      color: #999;
      cursor: pointer;
    }
  }

  .inp_box,.code_inp_box{
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }
    input[type="number"]{
      -moz-appearance: textfield;
    }
  }
  .login_title{
    // width: 100%;
    text-align: center;
    font-size: 30px;
    margin:10px;
    font-weight: 600;
  }
</style>
